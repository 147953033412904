import revive_payload_client_HndFO3gye1 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@netlify+blobs@8.0.1_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5._rpb7txigvjieutxfy7egywwzui/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_G9L7OhF3K8 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@netlify+blobs@8.0.1_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5._rpb7txigvjieutxfy7egywwzui/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3pDR6sCcFg from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@netlify+blobs@8.0.1_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5._rpb7txigvjieutxfy7egywwzui/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_DWiDdpanPv from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@netlify+blobs@8.0.1_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5._rpb7txigvjieutxfy7egywwzui/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_RyU69286Nv from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@netlify+blobs@8.0.1_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5._rpb7txigvjieutxfy7egywwzui/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_0quR0mcZmr from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@netlify+blobs@8.0.1_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5._rpb7txigvjieutxfy7egywwzui/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_tHLQ5G2oVV from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@netlify+blobs@8.0.1_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5._rpb7txigvjieutxfy7egywwzui/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_fre1YTTdaC from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@netlify+blobs@8.0.1_@types+node@22.5.5_eslint@9.10.0_rollup@4.21.3_typescript@5._rpb7txigvjieutxfy7egywwzui/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_be5RmlbuTq from "/opt/build/repo/node_modules/.pnpm/dayjs-nuxt@2.1.11_rollup@4.21.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import sentry_client_B86LNQ47Ie from "/opt/build/repo/node_modules/.pnpm/@sentry+nuxt@8.30.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_7w4nozss4zxlypsneyxm6zemj4/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import plugin_aIR1kyNCcu from "/opt/build/repo/node_modules/.pnpm/nuxt-icon@1.0.0-beta.7_rollup@4.21.3_vite@5.4.5_vue@3.5.5/node_modules/nuxt-icon/dist/runtime/plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_YIGOpIaaum from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_rollup@4.21.3_vite@5.4.5_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import clerk_XbpSWsrCmw from "/opt/build/repo/plugins/clerk.ts";
export default [
  revive_payload_client_HndFO3gye1,
  unhead_G9L7OhF3K8,
  router_3pDR6sCcFg,
  payload_client_DWiDdpanPv,
  navigation_repaint_client_RyU69286Nv,
  check_outdated_build_client_0quR0mcZmr,
  chunk_reload_client_tHLQ5G2oVV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fre1YTTdaC,
  plugin_be5RmlbuTq,
  sentry_client_B86LNQ47Ie,
  sentry_client_config_o34nk2sJbg,
  plugin_aIR1kyNCcu,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_YIGOpIaaum,
  clerk_XbpSWsrCmw
]